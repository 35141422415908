

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 25%;
    bottom: 20;
    margin: auto;
    background-color: rgba(121, 15, 15, 0.5);
  }
  .create-form-popup-inner {
    display: flex;
    position: absolute;
    left:10%;
    top: 8%;
    margin: auto;
    width:1200px;
    border-radius: 20px;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
	  background-size: cover;
    color: black;
  }

  .edit-popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 25%;
    z-index: 2;
    bottom: 20;
    margin: auto;
    overflow-x: auto;
    overflow-y: auto;
    background: linear-gradient(rgba(255, 252, 252, 0.6), rgba(247, 247, 247, 0.6)),
		no-repeat center,
		  linear-gradient(#ffffff, #14181a);
  }
  .edit-popup_inner {
    position: absolute;
    left:30%;
    top:8%;
    width:max-content;
    margin: auto;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    background-size: cover;
  }
  
  #save-button{
    background-color: lightgreen;
    bottom:1%;
    right:15%;
    position:absolute;
    padding-left: 30px;
    padding-right: 30px;
    padding-top:10px;
    padding-bottom:10px;
    border-radius: 10px;
  }

  #save-button:hover {
    background-color: rgb(152, 163, 135);
    box-shadow: 0 4px 8px 0 lightgreen;
    transition: all 0.8s ease;
  }

  #cancel-button {
    color: lightslategrey;
    position: absolute;
    bottom: 1%;
    left: 15%;
    padding-left: 30px;
    padding-right: 30px;
    padding-top:10px;
    padding-bottom:10px;
    border-radius: 10px;
  }
  
  #cancel-button:hover {
    background-color:rgb(175, 172, 172);
    box-shadow: 0 0px 8px 0 rgb(181, 179, 179);
    transition: all 0.7s ease;
  }

  .cancel-order-button {
    background-color: rgb(211, 61, 41) !important;
    color:white !important;
    font: bold !important;
    position: absolute !important;
    bottom: 1% !important;
    margin-left:40%!important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    padding-top:5px !important;
    padding-bottom:5px !important;
    border-radius: 10px !important;
  }
  
  .cancel-order-button:hover {
    background-color:rgb(175, 172, 172) !important;
    box-shadow: 0 0px 8px 0 rgb(181, 179, 179) !important;
    transition: all 0.7s ease !important;
  }

  h3 {
    background-color: rgba(78, 78, 78, 0.6);
    color: white;
    font-size: 30px;
    border-radius: 10px;
    padding:5px;
  }

  #header-3-label {
    color:rgb(240, 156, 0);
  }

  #add-new-dropdown {
    margin-top: 20%;
    margin-left: 30%;
  }

  #customer-deliver {
    background-color: rgba(232, 232, 232, 0.871);
    font-weight: bold;
    border-radius: 10px;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    color:rgb(0, 0, 0);
    padding:15px;
    width: fit-content;
    margin: left;
    font-size: larger;
  }
  #edit-customer-name-label {
    color:rgb(240, 156, 0);
  }
  #edit-delivery-name-label {
    color:rgb(240, 156, 0);
  }

  #edit-info-fields {
    background-color: rgba(232, 232, 232, 0.871);
    color:rgb(0, 0, 0);
    font-family: Tahoma, sans-serif;
    font-weight:bold;
    width: max-content;
    margin:auto;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    padding:15px;
  }
  #edit-input-field {
    display:block;
    padding: 5px;
    border:solid;
    border-color: rgb(161, 230, 235);
    border-width: 2px;
    border-radius: 6px;
  }
  #edit-input-field:focus{
    border:solid;
    border-color: rgb(72, 247, 72);
    border-width: 5px;
  }

  #product-edit-table {
    width: min-content;
    border:none;
    background-color: rgba(171, 174, 175, 0.4);
    margin:left;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
  }
  .product-edit-th {
    background-color: white;
    color:#5383d3;
    font-weight: bold;
    padding:5px;
    width:200px;
  }

  #edit-radio-option {
    position: relative;
  }

  .popup_outer_create {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 25%;
    bottom: 20;
    margin: auto;
    background-color: rgba(0,0,0,0.5);
  }
  .popup_inner_create {
    margin:0 auto;
    height: 200px;
    width: 50%;
    border-radius: 20px;
    background: white;
  }

  #save-popup-button{
    background-color: lightgreen;
    right:30%;
    top:20%;
    position:absolute;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
  }

  #cancel-popup-button {
    color: lightslategrey;
    position: absolute;
    top:20%;
    left: 30%;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
  }
  #add-new-delivery-input {
    margin:5% 35%;
    padding:5px;
    border:solid;
    border-color: rgb(161, 230, 235);
    border-width: 2px;
    border-radius: 6px;
  }
  #add-new-delivery-input:focus{
    border:solid;
    border-color: rgb(72, 247, 72);
    border-width: 2px;
  }
  #add-new-address-dropdown {
    margin-top: 10px;
    margin-left: 200px;
  }
  .edit-form-bottom-group {
    display: inline-flex;
    width: 1000px;
}

#edit-form-input-field {
  padding: 5px;
  border:solid;
  border-color: rgb(161, 230, 235);
  border-width: 2px;
  border-radius: 6px;
}
#edit-form-input-field:focus{
  border:solid;
  border-color: rgb(72, 247, 72);
  border-width: 5px;
}

.po-num-colored {
  border:thick !important;
  border-color: red !important;
  border-width: 5px !important;
  border-bottom-style: solid !important;
}

.edit-popup-sil-ed {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 25%;
  z-index: 2;
  bottom: 20;
  margin: auto;
  overflow-x: auto;
  overflow-y: auto;
  background: linear-gradient(rgba(255, 252, 252, 0.6), #663333),
  no-repeat center,
    linear-gradient(#ffffff, #14181a);
}