.App {
  text-align: center;
}

#row {
  background-color: rgb(233, 233, 233);
  padding: 15px;
  width:fit-content;
  font-family:"Heebo", sans-serif;
  font-weight: bold;
  color:rgb(0, 0, 0);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 15px 25px 25px !important;
  box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
  margin-left:0 auto;
}

.paper-card {
  background-color: rgb(233, 233, 233) !important;
  padding: 15px !important;
  /* width:fit-content; */
  /* font-family:"Heebo", sans-serif;
  font-weight: bold; */
  color:rgb(0, 0, 0) !important;
  border: 1px solid rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  padding: 15px 25px 25px !important;
  box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
  margin-left:0 auto !important; 
}

.paper-popup {
  overflow:auto;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 25%;
  bottom: 20;
  margin: auto;
  background-color: rgba(121, 15, 15, 0.5);
}
.paper-popup-inner {
  display:block;
  position: absolute;
  left:5%;
  top: 5%;
  margin: auto;
  width:80% !important;
  border-radius: 20px !important;
  background-color: rgb(243, 242, 242) !important;
  border: 1px solid rgb(204, 204, 204) !important;
  border-radius: 4px !important;
  padding: 15px 25px 25px !important;
  box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
  background-size: cover !important;
  color: black;
}

#clone-form-centered-items{
  background-color: rgb(243, 242, 242);
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
  padding: 15px 25px 25px !important;
  box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
  padding: 15px;
  width:fit-content;
  font-family:'Times New Roman';
  font-weight: bold;
  color:rgb(255, 255, 255);
  margin-left: 1%;
}
.clone-form-product-table-label{
  float:inline-start;
  font-size: 25px;
  font-weight: bold;
  color:#5383d3;
}

#submit-form-btn {
  background-color: rgb(150, 240, 150);
  padding: 15px;
  border-radius: 10px;
  margin-bottom: 10px;
 margin-left: 15%;
  font-size: 16px;
  color: darkslategray;
  font-weight: bold;
}

#submit-form-btn:hover {
  background-color: rgb(62, 224, 97);
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
  padding-top: 18px;
  padding-bottom: 18px;
  font-weight: 700;
  color:white;
}

#save-form-icon {
  font-size: 20px;
}

#cancel-create-btn {
  color:  darkslategray;
  background-color: default;
  margin-left:30%;
  margin-bottom:10px;
  font-size:16px;
  font-weight: bold;
  padding: 15px;
  border-radius: 10px;
}

#cancel-create-btn:hover {
  background-color: rgb(209, 129, 44);
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
  padding-top: 18px;
  padding-bottom: 18px;
  font-weight: 700;
  color:white;
}

#logout-btn{
  margin-left: 10%;
  background-color: rgb(174, 180, 184);
  color:white ;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  border-color: lightsteelblue;
  padding:12px;
}
#logout-btn:hover {
  background-color: rgb(114, 114, 114);
  color: rgb(151, 213, 255);
  padding-top: 10px;
  padding-bottom: 10px;  
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
}

#transport-company-logout-btn{
  margin-right: 10%;
  background-color: rgb(174, 180, 184);
  color:white ;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  border-color: lightsteelblue;
  padding:12px;
}
#transport-company-logout-btn:hover {
  background-color: rgb(114, 114, 114);
  color: rgb(151, 213, 255);
  padding-top: 10px;
  padding-bottom: 10px;  
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
}

#create-form-btn {
  margin-left:35%;
  background-color: rgb(80, 165, 221);
  color:white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  border-color: lightsteelblue;
  padding:12px;
}
#create-form-btn:hover {
  background-color: rgb(114, 114, 114);
  color: rgb(151, 213, 255);
  padding-top: 10px;
  padding-bottom: 10px;  
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
}

#create-contact-form-btn {
  margin-left:35%;
  background-color: rgb(80, 165, 221);
  color:white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  border-color: lightsteelblue;
  padding:12px;
}
#create-contact-form-btn:hover {
  background-color: rgb(114, 114, 114);
  color: rgb(151, 213, 255);
  padding-top: 10px;
  padding-bottom: 10px;  
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
}
#transport-company-create-contact-form-btn {
  margin-left:10%;
  background-color: rgb(80, 165, 221);
  color:white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  border-color: lightsteelblue;
  padding:12px;
}
#transport-company-create-contact-form-btn:hover {
  background-color: rgb(114, 114, 114);
  color: rgb(151, 213, 255);
  padding-top: 10px;
  padding-bottom: 10px;  
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
}
.create-customer-contact-form-btn{
  margin-left:15%;
  background-color: rgb(80, 165, 221);
  color:white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  border-color: lightsteelblue;
  padding:12px;
}

.create-customer-contact-form-btn:hover {
  background-color: rgb(114, 114, 114);
  color: rgb(151, 213, 255);
  padding-top: 10px;
  padding-bottom: 10px;  
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
}

.showError{    
  border-color: #a94442 !important;    
  color: #a94442 !important;    
  margin-bottom: 15px;    
  border-radius: 5px;
  border-width: 3px;
}


.showSuccess{    
  border-color: green !important;    
  color: green !important;    
  margin-bottom: 15px;    
  border-radius: 5px;
  border-width: 3px;
}

.header-fixed {
  /* background-color:#292c2fda; */
  background-color: #f5f2f1;
  background-image: URL('https://apps.propsense.com/static/media/headerImg.b4e78b0f.png');
	box-shadow:0 1px 1px #ccc;
	padding: 20px 40px;
	height: 80px;
	color: #ffffff;
	box-sizing: border-box;
	top:-100px;
	-webkit-transition:top 0.3s;
	transition:top 0.3s;
  width: 100%;
}
.header-fixed .header-limiter {
	max-width: 1200px;
	text-align: center;
	margin: 0 auto;
  color:#b6b8ba;
}

.header-fixed .header-limiter h2 {
	font: normal 28px Cookie, Arial, Helvetica, sans-serif;
	line-height: 40px;
	margin: auto;
  font-size: 50px;
	font-weight: 200;
	letter-spacing: .01em;
	text-shadow: .022em .022em .022em #111;
}

.header-fixed .header-limiter h2 span {
	color: #5383d3;
}

.order-info-fixed {
  margin-top: 0px;
  margin-left: 0px;
  padding: 50px 50px;
  border-radius: 0px;
  border-width: 10px;
  display: table;
  border-style:solid;
  border-color: #5e646b;
 /* background-color: #292c2f; */
  background-color: white;
}
/* Used for datagrid inside div without display:table*/
.data-grid-fixed {
  margin-top: 0px;
  margin-left: 0px;
  padding: 50px 50px;
  border-radius: 0px;
  border-width: 10px;
  border-style:solid;
  border-color: #5e646b;
 /* background-color: #292c2f; */
  background-color: white;
}
.commentsEditForm{
  background-color: white;
}

.header-fixed-sil-ed {
  /* background-color:#292c2fda; */
  background-color: #c2c2c2;
  /* background-image: URL('https://apps.propsense.com/static/media/headerImg.b4e78b0f.png'); */
	box-shadow:0 1px 1px #ccc;
	padding: 20px 40px;
	height: 80px;
	color: #ffffff;
	box-sizing: border-box;
	top:-100px;
	-webkit-transition:top 0.3s;
	transition:top 0.3s;
  width: 100%;
}
.header-fixed-sil-ed .header-limiter {
	max-width: 1200px;
	text-align: center;
	margin: 0 auto;
  color:#ffffff;
}

.header-fixed-sil-ed .header-limiter h2 {
	font: normal 28px Cookie, Arial, Helvetica, sans-serif;
	line-height: 40px;
	margin: auto;
  font-size: 50px;
	font-weight: 200;
	letter-spacing: .01em;
	text-shadow: .022em .022em .022em #111;
}

.header-fixed-sil-ed .header-limiter h2 span {
	color: #a73030;
}

#create-form-btn-sil-ed {
  margin-left:35%;
  background-color: #a73030;
  color:white;
  font-size: 15px;
  font-weight: bold;
  border-radius: 8px;
  border-color: rgb(216, 216, 216);
  padding:12px;
}
#create-form-btn-sil-ed:hover {
  background-color: rgb(114, 114, 114);
  color: #a73030;
  padding-top: 10px;
  padding-bottom: 10px;  
  box-shadow: 0 0px 8px 0 grey;
  transition: all 0.5s ease;
}
.data-grid-fixed-sil-ed {
  margin-top: 0px;
  margin-left: 0px;
  padding: 50px 50px;
  border-radius: 0px;
  border-width: 10px;
  border-style:solid;
  border-color: #5e646b;
 /* background-color: #292c2f; */
  background-color: #5a1212;
}
.paper-popup-sil-ed {
  overflow:auto;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 25%;
  bottom: 20;
  margin: auto;
  /* background-color: #5a1212 !important; */
  background-color: #5a1212 !important;
}