

.product-col {
    height: 40px;
    border-style: ridge;
    background-color: rgb(124, 169, 184, 0.3);
    color:white;
    border:none;
    background-blend-mode: darken;
}

.table-product-order {
    margin-left: 0%;
}

.product-row {
    height: 15px;
    background-color: rgb(209, 209, 209, 0.3);
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 18px;
    border-width: medium;
    padding: 10px;
    letter-spacing: 1px;
    border:none;
}

.product-row-add {
    height: 15px;
    background-color: rgb(171, 216, 231, 0.4);
    margin-bottom: 2px;
    font-weight: 400;
    border:none;
    font-size: 18px;
    border-width: medium;
    padding: 10px;
    letter-spacing: 1px;
}

#add-product-btn {
    background-color: rgb(150, 240, 150);
    padding: 5px;
    font-size: 12px;
    border-width: 2px;
    border-radius: 8px;
    width: max-content;
    font-weight: bold;
}
#add-product-btn:hover {
    background-color: rgb(122, 122, 122);
    box-shadow: 0 4px 8px 0 rgb(0, 0, 0);
    transition: all 0.8s ease;
    padding:5px;
    color:rgb(115, 241, 56);
    font-weight: 700;
  }
  .cancel-add-product-btn {
    background-color: rgb(230, 228, 227) !important;
    padding: 5px !important;
    font-size: 12px !important;
    border-width: 2px !important;
    border-radius: 8px !important;
    width: max-content !important;
    font-weight: bold !important;
}
.cancel-add-product-btn:hover {
    background-color: rgb(230, 228, 227);
    box-shadow: 0 4px 8px 0 rgb(0, 0, 0);
    transition: all 0.8s ease;
    padding:5px;
    padding-top:10px;
    padding-bottom:10px;
    color:rgb(122, 122, 122);
    font-weight: 700;
  }

#del-product-btn {
    background-color: rgb(194, 34, 34);
    color:white;
    font-weight: 700;
}
#del-product-btn:hover {
    padding-top:5px;
    padding-bottom:5px;
    background-color: rgb(255, 153, 153);
    box-shadow: 0 0px 8px 0 grey;
    transition: all 0.5s ease;
    color:rgb(0, 0, 0);
    font-weight: 700;
}

.edit-del-product-btn {
    background-color: rgb(194, 34, 34) !important;
    color:white !important;
    width: 20px !important; 
    height: 20px !important;
}
.edit-del-product-btn:hover {
    padding-top:2px !important;
    padding-bottom:2px !important;
    background-color: rgb(255, 153, 153) !important;
    box-shadow: 0 0px 8px 0 grey !important;
    transition: all 0.5s ease !important;
    color:rgb(0, 0, 0) !important;
}

.form-select-location {
    margin-left: 40%;
    background-color: rgb(198, 204, 216, 0.3);
    padding: 15px;
    width:150px;
    padding:5px;
    font-size: 20px;
    font-family:'Times New Roman';
    color: white;
    font-weight: bold;
    border-width: 5px;
    border-radius: 10px;
    border:solid;
    border-color:rgba(255, 255, 255, 0.3);
}
#asterix {
    color:red;
}