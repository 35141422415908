.customer-group {
    background-color:  rgba(232, 232, 232, 0.871);
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: larger;
    font-family:"Heebo", sans-serif;
    width:max-content;
    border-style: groove;
    border-color: rgba(255,255,255, 0.5);
    padding: 5px;
    margin:left;
    margin-top: 1%;
}

#create-customer {
    left: 20px;
    background-color: rgb(150, 240, 150);
    padding: 5px;
    border-radius: 10px;
    border-style: solid;
    font-size: 20px;
    font-weight: bold;
}
#create-customer:hover {
    padding-top:5px;
    padding-bottom:5px;
    background-color: grey;
    box-shadow: 0 0px 8px 0 grey;
    transition: all 0.5s ease;
    color:rgb(115, 241, 56);
  }
#create-delivery {
    left:20px;
    background-color: rgb(150, 240, 150);
    border-radius: 10px;
    border-style: solid;
    padding: 5px;
    font-size: 20px;
    font-weight: bold;
}
#create-delivery:hover {
    padding-top:5px;
    padding-bottom:5px;
    background-color: grey;
    box-shadow: 0 0px 8px 0 grey;
    transition: all 0.5s ease;
    color:rgb(115, 241, 56);
  }
  #create-customer-contact {
      left:0%;
    background-color: rgb(150, 240, 150);
    border-radius: 10px;
    border-style: solid;
    padding: 5px;
    font-size: 20px;
    font-weight: bold;
  }
  #create-customer-contact:hover {
    padding-top:5px;
    padding-bottom:5px;
    background-color: grey;
    box-shadow: 0 0px 8px 0 grey;
    transition: all 0.5s ease;
    color:rgb(115, 241, 56);
  }
.table-rendered {
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    background-color: rgba(171, 174, 175, 0.4);
}

table {
    text-align: center;
    border-collapse: collapse;
    width: 75%;
  }
th {
    height: 40px;
    background-color: white;
    color:#5383d3;
    font-weight: bold;
    padding:5px;
    width:200px;
}


.add-customer-field {
    background-color: rgb(198, 220, 250);
    width: 75%;
}

.add-customer-field:focus {
    background-color: rgb(198, 220, 250);
    width: 75%;
    border:solid;
    border-color: rgb(72, 247, 72);
    border-width: 5px;
}

#del-customer-btn {
    background-color: rgb(194, 34, 34);
    color: white;
}

.del-customer-btn {
    background-color: rgb(194, 34, 34) !important;
    color: white !important;
    padding: 5px;
    font-size: 12px;
    border-width: 2px;
    border-radius: 4px;
    width: 50px;
    font-weight: bold;
}
#del-customer-btn:hover {
    padding-top:5px;
    padding-bottom:5px;
    background-color: rgb(255, 153, 153);
    box-shadow: 0 0px 8px 0 grey;
    transition: all 0.5s ease;
    color:rgb(0, 0, 0);
    font-weight: 700;
}

.customer-row-add {
    height: 15px; 
    background-color: rgba(213, 212, 212, 0.946);
    margin-bottom: 2px;
    font-weight: 400;
    border:none;
    font-size: 18px;
    border-width: medium;
    padding: 10px;
    letter-spacing: 1px;
}

.customer-row {
    height: 15px;
    background-color: rgb(209, 209, 209, 0.3);
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 18px;
    border-width: medium;
    padding: 10px;
    letter-spacing: 1px;
    border:none;
}

.contact-table-input {
    padding:2px;
    font-family: 'Raleway', sans-serif;
    padding: 5px;
    border:none;
    outline:none;
    border-bottom:solid;
    border-bottom-width: 2px;
    border-color:rgb(204, 204, 204);
}

.contact-table-input:focus{
   border-width: 1px;
   border:solid rgba(63, 255, 63, 0.467);
}

#multi-select-filters {
    color:black;
    margin:auto;
    width: fit-content;
    background-color: rgba(72, 73, 73, 0.172);
}