
.popup {
    position:absolute;
    width: 150%;
    height: 160%;
    margin: auto;
    background-color: rgba(194, 194, 194, 0.5);
  }
  .popup_inner {
    position: absolute;
    left:10%;
    top: 8%;
    margin: auto;
    border-radius: 20px;
    background: white;
    color: black;
  }

  h1 {
    background-color: lightgray;
    color: white;
    border-style: groove;
    font-size: 30px;
  }