
.form-check {
    margin-left: 200px;
}

.truckinfo {
    display: inline-flex;
    justify-content: center;
}
#first {
    margin-left: 25%;
}
#second {
    margin-left: 0%;
}
#third {
    margin-right: 0%;
}
#truck-info-fields{
    margin-left:15%;
}
#radio-option {
    position: relative;
}

.truck-require {
    position: relative;
    justify-content: center;
}
