
#create-contact-row {
    background-color: rgba(232, 232, 232, 0.871);
    width:fit-content;
    text-align: left;
    border:solid;
    font-family:"Heebo", sans-serif;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    padding:15px;
    font-weight: bold;
    color:rgb(0, 0, 0);
    border-color:rgba(255, 255, 255, 0.3);
    margin:auto;
  }

  .create-contact-form-popup-inner {
    display: flex;
    position: absolute;
    top: 8%;
    margin-left: 20%;
    width: max-content;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
	  background-size: cover;
    color: black;
  }

  #submit-contact-form-btn {
    background-color: rgb(150, 240, 150);
    padding: 15px;
    border-radius: 10px;
    margin-bottom: 10px;
    float:right;
    font-size: 16px;
    color: darkslategray;
    font-weight: bold;
  }
  
  #submit-contact-form-btn:hover {
    background-color: rgb(62, 224, 97);
    box-shadow: 0 0px 8px 0 grey;
    transition: all 0.5s ease;
    padding-top: 18px;
    padding-bottom: 18px;
    font-weight: 700;
    color:white;
  }

  #cancel-contact-form-btn {
    color:  darkslategray;
    background-color: default;
    float:left;
    margin-bottom:10px;
    font-size:14px;
    font-weight: bold;
    padding: 15px;
    border-radius: 10px;
  }
  
  #cancel-contact-form-btn:hover {
    background-color: grey;
    box-shadow: 0 0px 8px 0 grey;
    transition: all 0.5s ease;
    padding-top: 18px;
    padding-bottom: 18px;
    font-weight: 700;
    color:white;
  }

#form-group-contact-create{
    text-align: right;
}
#customer-group-contact {
    background-color:rgb(247, 244, 244);
    color: rgb(0, 0, 0);
    font-weight: bold;
    width:max-content;
    border-style: groove;
    border-color: rgba(255,255,255, 0.5);
    padding: 5px;
    margin-left: 0%;
    margin-top: 1%;
}

#edit-contact-input-field {
  display:block;
  padding: 5px;
  border:solid;
  border-color: rgb(161, 230, 235);
  border-width: 2px;
  border-radius: 6px;
}
#edit-contact-input-field:focus{
  border:solid;
  border-color: rgb(72, 247, 72);
  border-width: 5px;
}
#contact-page-label {
  float:left;
}