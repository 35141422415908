
#nav-bar-fixed {
    position: absolute;
    z-index: 2 !important;
}
/* Position Radio button group so it does not overlap the navbar*/
.orders-radio-group-position {
    position: absolute;
    margin-left: 15%;
}
.location-radio-group-position {
    position: absolute;
    margin-left: 75%;
}
#nav-bar {
   border-style: solid;
   border-radius: 5px;
   border-width: 2px;
   width: 160px;
   border-color: rgb(153, 153, 153);
   background-color: #292c2f;
}
#nav-dropdown-icon {
    font:25px bold;
    color:white;
}
#nav-dropdown-icon:hover {
    background-color: rgb(255, 255, 255);
}
#nav-dropdown-content {
    border-color: rgb(153, 153, 153);
   background-color: rgb(153, 153, 153);
   border-style: solid;
   border-radius: 5px;
   border-width: 4px;
}
#nav-bar-item {
    background-color: rgb(204, 204, 204);
    color:white;
    font: 20px;
    padding:5px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none; 
    background-color: #292c2f;
    display:flex; 
    width: 200px; height: fit-content;
    border-bottom: rgb(233, 233, 233) solid 1px;
}
#nav-bar-item:hover {
    background-color: rgb(98, 183, 209);
}
/* Used to adjust padding for warehouse navigation menu to prevent overlapping with radio buttons */
#nav-bar-wh-item {
    background-color: rgb(204, 204, 204);
    color:white;
    font: 20px;
    padding:5px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none; 
    background-color: #292c2f;
    display:flex; 
    width: 160px; height: fit-content;
    border-bottom: rgb(233, 233, 233) solid 1px;
}
#nav-bar-wh-item:hover {
    background-color: rgb(98, 183, 209);
}

#nav-bar-wh-submenu {
    background-color: rgb(204, 204, 204);
    color:white;
    font: 20px;
    padding:3px;
    margin-left:100%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: none; 
    background-color: #292c2f;
    display:flex; 
    width: 160px; height: fit-content;
    border-bottom: rgb(233, 233, 233) solid 1px;
}
#nav-bar-wh-submenu:hover {
    background-color: rgb(98, 183, 209);
}
