
.grid-view-product-lookup-table {
    text-align: center;
    border-collapse: collapse;
    border:none;
    width: 100%;
}
.edit-product-lookup-inner {
    position: absolute;
    left:40%;
    top:8%;
    width:max-content;
    margin: auto;
    background-color: rgb(243, 242, 242);
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    background-size: cover;
  }

  #edit-product-lookup-fields {
    background-color: rgb(198, 204, 216, 0.3);
    color:white;
    font-family: Tahoma, sans-serif;
    font-weight:bold;
    width: 350px;
    margin-left:80px;
    border-radius: 10px;
    border:solid;
    border-color:rgba(255, 255, 255, 0.6);
  }
  .product-info-fixed {
    margin-top: 0px;
    margin-left: 0px;
    padding: 50px 50px;
    border-radius: 0px;
    border-width: 10px;
    width: 100%;
    border-style:solid;
    border-color: #5e646b;
   /* background-color: #292c2f; */
    background-color: white;
  }
  