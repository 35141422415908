

.back-btn {
    background-color: rgb(55, 110, 228) !important;
    color: white !important;
    padding: 5px;
    font-size: 12px;
    border-width: 2px;
    border-radius: 8px;
    width: max-content;
    font-weight: bold;
}