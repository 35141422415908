
.grid-view-table {
    text-align: center;
    border-collapse: collapse;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    width: 100%;
}

.data-grid-table {
    position:inherit !important;
    padding: 15px 25px !important;
}

.customer-grid-view-table {
    text-align: center;
    border-collapse: collapse;
    border: 1px solid rgb(204, 204, 204);
    border-radius: 4px;
    padding: 15px 25px 25px !important;
    box-shadow: rgb(69 65 78 / 8%) 0px 1px 15px 1px !important;
    width: 100%;
}

.gridview-th {
    height: 40px !important;
    padding: 15px !important;
    background-color: white !important;
    color:#5383d3 !important;
    font-size: 18px !important;
    font: Cookie, Arial, Helvetica, sans-serif !important;
    background-blend-mode: darken !important;
    border-bottom-style:solid !important;
    border-bottom-width: 6px !important;
    border-bottom-color: #5383d3 !important;
}

.inner-product-table {
    height: 10px;
     text-align: center;
    border-collapse: collapse;
    border: 1px solid black;
}

.tr-test:nth-child(odd) {
   /*background-color: rgb(228, 220, 222);*/
    /* background-color: #d3e3ff; */
    background-color: #e7e9ed;
}
/* Gridview background color
 * Even - blue
 * Odd - white
 * Completed Orders - green
 * Orders ready to pickup - orange
*/
.tr-test:nth-child(even) {
    background-color: rgb(255, 255, 255);
}
.completed-order-bg-color{
    background-color: rgb(146, 218, 150);
    height: 15px !important;
    font-family:Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
}
.ready-for-pickup-order-bg-color{
    background-color: rgb(236, 176, 65);
    height: 15px !important;
    font-family:Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
}
.cancelled-order-bg-color{
    background-color: rgb(241, 25, 25);
    height: 15px !important;
    font-family:Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
}
/* Hovers */
.ready-for-pickup-order-bg-color:hover{
    background-color: #c9e0ff !important;
}
.completed-order-bg-color:hover{
    background-color: #c9e0ff !important;
}
.cancelled-order-bg-color:hover{
    background-color: #c9e0ff !important;
}
.tr-test:hover {background-color: #c9e0ff;}

.grid-view-row {
    height: 15px !important;
    color: black !important;
    margin-bottom: 2px !important;
    font-family:Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    border-width: medium !important;
    padding: 15px !important;
    width:500px !important;
    letter-spacing: 1px !important;
    white-space:nowrap !important;
    border:none !important;
}
/* Used for Material UI datagrid rows*/
.data-grid-row-style {
    height: 15px !important;
    font-family:Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
}
.data-grid-row-style-clicked{
    height: 15px !important;
    font-family:Tahoma, Geneva, Verdana, sans-serif !important;
    font-size: 16px !important;
    letter-spacing: 1px !important;
    background-color: rgb(146, 218, 150);
}
.data-grid-row-style:nth-child(even){
    background-color: rgb(255, 255, 255);
}
.data-grid-row-style:nth-child(odd){
    background-color: #e7e9ed;
  }
.data-grid-row-style:hover {
    background-color: #c9e0ff !important;
}


.inner-product-table-th {
    width: 100%;
    height: 40px;
    padding: 15px;
    background-color: #292c2f;
    color:white;
    font-size: 18px;
    font: Cookie, Arial, Helvetica, sans-serif;
    background-blend-mode: darken;
}
.inner-product-table-tr {
    padding:0 15px 0 15px;
    width: 200px;
    /* border-spacing: 15px 0px; */
    display:table-cell;
    text-align: center;
    color: black;
    padding:10px;
    font-family:Tahoma, Geneva, Verdana, sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
}
#edit-grid-table {
    background-color: white;
    padding: 5px;
}
.devex-styled-grid-row-default {
    color: black !important;
    font: Cookie, Arial, Helvetica, sans-serif !important;
    font-size: 14px !important;
    padding: 5px !important;
}
.dx-state-hover
{
background-color:#CFCFCF !important; 
} 

.devex-styled-grid-row-green{
    background-color: rgb(146, 218, 150);
    /* height: 15px !important; */
    color: black !important;
    font: Cookie, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    padding: 5px !important;
}
.devex-styled-grid-row-orange {
    background-color: rgb(236, 176, 65);
    /* height: 15px !important; */
    color: black !important;
    font: Cookie, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    padding: 5px !important;
}
.devex-styled-grid-row-red {
    background-color: rgb(241, 25, 25);
    /* height: 15px !important; */
    color: black !important;
    font: Cookie, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    padding: 5px !important;
}

.devex-styled-grid-row-loading {
    background-color: rgb(155, 200, 252);
    /* height: 15px !important; */
    color: black !important;
    font: Cookie, Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    padding: 5px !important;
}
/* .gridContainer tr {
    font: Cookie, Arial, Helvetica, sans-serif !important;
} */

.dx-datagrid-headers .dx-header-row {
    background-color: rgb(236, 236, 236);
    font-size: 16px;
    font-weight: bold;
}
