

.siteinfo {
    display: inline-flex;
    justify-content: center;
}
#first {
    margin-left: 20%;
}
#second {
    margin-left: 20%;
}
#third {
    margin-left: 5%;
    display: block;
}
#fourth {
    margin-left:-100px;
}

.site-require {
    margin-left:0%;
    display: inline-flex;
    justify-content: center;
}

.form-check {
    display: inline-flex;
    justify-content: center;
  
}


select {
    outline: 0;
    background-color: #3c5369;
    background-image: none;
    border:none;
    border-width: 10px;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    padding-top:0.5em;
    padding-bottom:0.5em;
}

select::-ms-expand {
    display: none;
}
select:hover{
    color:rgb(81, 189, 197);
    font-weight: bold;
}

/*
 * Used for all borders of dropdowns in create form popup
*/
#create-dropdown-options {
    border:solid;
    border-color: rgb(161, 230, 235);
    border-width: 1px;
    border-radius: 6px;
  }
  #create-dropdown-options:focus{
    border:solid;
    border-color: rgb(72, 247, 72);
    border-width: 2px;
  }
